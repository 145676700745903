.stacked-images1 {
    position: absolute;
    width: 450px;
    height: 450px;
    top: 25%;
    right: 7%;
}
  
.stacked-images1 img {
    position: absolute;
    width: 450px;
    height: 450px;
}
.image-11 {
    animation: rotateLeft 40s linear infinite; /* Animation infinie */
    }
    
    .image-31 {
    animation: rotateRight 50s linear infinite; /* Animation infinie */
    }
    
    .image-51 {
    animation: rotateLeft 70s linear infinite; /* Animation infinie */
    }
    
    .image-21 {
    animation: rotateRight 50s linear infinite; /* Animation infinie */
    }
    .image-41 {
    animation: rotateLeft 10s linear infinite; /* Animation infinie */
    }
    
    .image-61 {
    animation: rotateRight 30s linear infinite; /* Animation infinie */
    }
    
    .image-71 {
    animation: rotateLeft 40s linear infinite; /* Animation infinie */
    }
    
    
    @keyframes rotateLeft {
    
        50% {
        transform: rotate(-300deg);
        }
        100% {
        transform: rotate(0deg);
        }
    }
    
    @keyframes rotateRight {
    
        50% {
        transform: rotate(300deg);
        }
        100% {
        transform: rotate(0deg);
        }
    }

    
