.Split_img_container_small{
    border-radius: 7px;
    width: 650px;
    height: 550px;
}
.Split_img_container_small img{
    width: 650px;
    height: 550px;
    border-radius: 7px;

}

.Split_img_container_small:hover{
    transform: scale(1.05);
    transition: .3s;
}
@media  screen and (max-width: 1400px) {
    .Split_img_container_small{
        width: 450px; 
        height: 350px;
        margin-top: 5%;
    }
    .Split_img_container_small img{
        width: 450px;
        height: 350px;
        border-radius: 7px;
    
    }
}
@media  screen and (max-width: 1200px) {
    .Split_img_container_small{
        width: 350px; 
        height: 250px;
        margin-top: 5%;
    }
    .Split_img_container_small img{
        width: 300px;
        height: 250px;
        border-radius: 7px;

    }
}