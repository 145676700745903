.split__left{
    justify-content: center;
    align-items: center;
  
}

.Split__container__left{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 90%;
    margin-left: 5%;
    margin-bottom: 5%;
    margin-top: 5%;
    align-items: center;
}
@media  screen and (max-width: 1400px) {
  .Split__container__left{
    width: 40%;
}
  }
  @media  screen and (max-width: 1300px) {
   
  }
  @media  screen and (max-width: 1200px) {
    .Split__container__left{
        width: 40%;
    }
  }
@media  screen and (max-width: 960px) {
    .Split__container__left {
        flex-direction: column; /* Stack items vertically */
        align-items: center;
      }
    
      .split-content {
        width: 50%;
        margin-bottom: 20px; /* Adjust the margin as needed */
      }
    
}
@media  screen and (max-width: 760px) {
    .Split__container__left {
        justify-content: center;
        width: 80%; /* Set the width to 100% to center the content */
        margin-left: 0; /* Remove the left margin */
      }
      .split__left {
        justify-content: center;
        margin-left: 10%;
      }
}
@media  screen and (max-width: 560px) {
    .Split__container__left {
        justify-content: center;
        width: 30%; /* Set the width to 100% to center the content */
        margin-left: 0; /* Remove the left margin */
      }
    
      .split__left {
        justify-content: center;
      } 
}