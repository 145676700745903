.Split__text_container__black{
    width: 650px;
}
.Split__title__black{
    color: black;
    font-size: 35px;
    line-height: 45px;
    font-weight: 700;
    margin-bottom: 5%;
}
.Split__desc__black{
    color: gray;
    font-size: 20px;
    font-weight: 200;
}
@media  screen and (max-width: 1400px) {
    .Split__text_container__black{
        width: 450px;
        margin-top: 2%;
    }
  }
  @media  screen and (max-width: 1200px) {
    .Split__text_container__black{
        width: 350px;
        margin-top: 5%;
    }
  }
  @media  screen and (max-width: 960px) {
    .Split__container{
        text-align: center;
        margin-top:5%;
    }
}
@media  screen and (max-width: 760px) {
    .Split__container{
        text-align: center;
        margin-top:5%;
    }
    .Split__text_container__black{
        width: 300px;
        margin-top: 5%;

    }
    .Split__title__black{
        color: gray;
        font-size: 25px;
        line-height: 35px;
        font-weight: 700;
        margin-bottom: 5%;
    }
    .Split__desc__black{
        color: gray;
        font-size: 15px;
        font-weight: 200;
    }
}