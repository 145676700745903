.container{

    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    
}
 .box__container{
    padding-top: 4%;
    padding-bottom: 4%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    background-color:rgb(242, 253, 253);
}
.carousel_img{
    height: 500px;
}


 
.box__mor{
    border: none;
    width: 50%;
    height:500px;
    background-color: rgb(242, 253, 253); 
  }
 
  .box_title{
    font-size: 35px;
    line-height: 45px;
    font-weight: 700;
    padding: 3%; 
  }
 
  .box_desc{
 
    margin: 0;
    padding: 3%;
    font-size: 17px;
    line-height: 28px;
    color: #afafaf;
    font-weight: 500;
  } 
 
  .our_services{
    padding: 3%;
  }
    
  .box__img{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .splash__bg {
    position: absolute;
    width: 50%;
    height: 500px;
    justify-content: center;
    align-items: center;
    z-index: 1;  
  }
    
 .js-circle  {
    position: absolute;
    display: block;
    top: 40%;
    left: 45%;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid #66e8dc;
    box-sizing: border-box;
}
@media  screen and (max-width: 960px) {
    .box__img{
        
    }
    .box__mor{
        text-align: center;
        width: 100%;
        height:500px;
        background-color: rgb(242, 253, 253); 
      }
  }
  @media  screen and (max-width: 660px) {
    .carousel_img{
        height: 300px;
    }
    .box__img{
        
    }
    .box__mor{
        text-align: center;
        width: 100%;
        height:500px;
        background-color: rgb(242, 253, 253); 
        margin-bottom: 40%;
      }
      .js-circle  {
        position: absolute;
        display: block;
        top: 40%;
        left: 45%;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        border: 1px solid #66e8dc;
        box-sizing: border-box;
    }
  } 