.small__container__dc{
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 500px;
    padding: 2%;
    display: flex;
    flex-wrap: wrap;
    text-decoration: none;
    box-shadow: 0 25px 98px 0 rgba(0, 0, 0, 0.068);
    transition: 0.3s;
    border-radius: 7px;
    height: 100%;
    border: .1px solid rgba(128, 128, 128, 0.386);
    margin-top: 6%;
    background-color: white;
}
.small__container__dc:hover{
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    transform: scale(1.05);
}
.small__container__dc img{
    width: 40%;
    border-radius: 5px ;
  }
  .small__container__dc .small__dc__text{
    width: 60%;
    padding-left: 2%;
  }
.small__dc__text h1{
    color: black;
    font-size: 22px;
    font-weight: 200;
}
.small__dc__text p{
    font-size: 15px;
    font-weight: 200;
    color: #afafaf;
}
@media screen and (max-width:960px){
    
    .small__container__dc{
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 400px;
        padding: 2%;
        display: flex;
        flex-wrap: wrap;
        text-decoration: none;
        box-shadow: 0 25px 98px 0 rgba(0, 0, 0, 0.068);
        transition: 0.3s;
        border-radius: 7px;
        height: 100%;
        border: .1px solid rgba(128, 128, 128, 0.386);
        margin-top: 6%;
        background-color: white;
    }
    .small__container__dc img{
        width: 40%;
        border-radius: 5px ;
      }
    .small__dc__text h1{
        color: black;
        font-size: 17px;
        font-weight: 200;
    }
    .small__dc__text p{
        font-size: 12px;
        font-weight: 200;
        color: #afafaf;
    }
}
@media screen and (max-width:660px){
    
    .small__container__dc{
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 300px;
        padding: 2%;
        display: flex;
        flex-wrap: wrap;
        text-decoration: none;
        box-shadow: 0 25px 98px 0 rgba(0, 0, 0, 0.068);
        transition: 0.3s;
        border-radius: 7px;
        height: 100%;
        border: .1px solid rgba(128, 128, 128, 0.386);
        margin-top: 6%;
        background-color: white;
    }
    .small__container__dc img{
        width: 40%;
        border-radius: 5px ;
      }
    .small__dc__text h1{
        color: black;
        font-size: 17px;
        font-weight: 200;
    }
    .small__dc__text p{
        font-size: 12px;
        font-weight: 200;
        color: #afafaf;
    }
}