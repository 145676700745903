
.List__container{
    width: 320px;
    height: 800px;
    padding: 40px 30px;
    box-shadow: 0 25px 98px 0 rgba(0, 0, 0, 0.068);
    border-radius: 4px;
    margin: 2%;
    margin-top: 6%;
    
}
.List__icon i{
    font-size: 30px;
    font-size: 28px;
    width: 70px;
    height: 70px;
    line-height: 70px;
    border-radius: 50%;
    text-align: center;
    color: #3cc2bb;
    background-color: rgb(10 191 83 / 17%);
}
.List__icon{
    padding-bottom: 5%;
}

.List__text h4{
    font-size: 20px;
    padding-bottom: 10%;
    color: black;
}
.List__text p{
    font-size: 17px;
    line-height: 28px;
    color: #afafaf;
    font-weight: 500;
}
.List__container:hover .List__icon i{
    color: #fff;
    background-color: rgb(10 191 83 / 17%);
    transition: 1s;
}
.List__container:hover {
   background-color: #01c7ef49;
   transition: 1.5s;
}
@media  screen and (max-width: 800px) {
    .List__container{
        width: 320px;
        height: 100%;
    }
  }