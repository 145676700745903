.split{
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 15%;
}

.Split__container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 90%;
    margin-left: 5%;
    margin-bottom: 5%;
    /* padding-top: 5%; */
    /* margin-top: 5%; */
    align-items: center;
}
@media  screen and (max-width: 1400px) {
    .Split__container{
        width: 85%;
    }
  }
@media  screen and (max-width: 960px) {
    .Split__container{
        display: flex;
        justify-content: center;
    }
}