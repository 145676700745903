.Image_SC{
    width: 95%;
    align-items: center;
    margin-top: -5%;
    z-index: 2;
}
.seconde-container .image-back{
    object-fit: cover;
    width: 95%;
    height: 500px;
    position: absolute;
    z-index: -1;
    border-radius: 6px;
    transition: transform 0.3s ease;
   
}

.seconde-container {
    
    height: 70%;
    width: 100%;
    padding-bottom: 10%;
    display: flex;
    justify-content: center;
    object-fit: contain;
    border-radius: 6px;
    background-color: rgb(242, 253, 253);
    padding-top: 5%;
    

}
.Image_SC:hover .image-back{
    transform: scale(1.04);
}

.seconde-container h1{
    color: #fff;
    font-size: 70px;
    margin-left: 8%;
    margin-top: 2%;
}
.seconde-container p{
    color: #fff;
    font-size: 55px;
    margin-left: 8%;
    margin-top: 2%;
}
.imageV{
    width: 170px;
    height: 150px;
    margin-left: 5%;
    margin-top: 5%;
}
.background{
    z-index: 1;
    opacity:0.7;
}
@media screen and (max-width:1366px){
    .seconde-container h1{
        font-size: 45px;
    }
    .seconde-container p{
        font-size: 40px;
    }
    .seconde-container .image-back{
        object-fit: cover;
        width: 90%;
        height: 60%;
        position: absolute;
        z-index: -1;
    }
    .seconde-container:hover{
        /* transform: none; */
    }
    .imageV{
        width: 150px;
        height: 130px;
        margin-left: 5%;
        margin-top: 40px;
    }
    .Image_SC{
        width: 90%;
        align-items: center;
        z-index: 2;      
    }
    .seconde-container {
        height: 100vh;
        width: 100%;
        margin-top: -15%;
        display: flex;
        justify-content: center;
        object-fit: contain;
        border-radius: 6px;
        align-items: center;
        transition: transform 0.3s ease;
    }
}
@media screen and (max-width:960px){
    .seconde-container h1{
        font-size: 45px;
    }
    .seconde-container p{
        font-size: 40px;
    }
    .seconde-container .image-back{
        object-fit: cover;
        width: 90%;
        height: 60%;
        position: absolute;
        z-index: -1;
    }
    .seconde-container:hover{
        /* transform: none; */
    }
    .imageV{
        width: 150px;
        height: 130px;
        margin-left: 5%;
        margin-top: 40px;
    }
    .Image_SC{
        width: 90%;
        align-items: center;
        z-index: 2;      
    }
    .seconde-container {
        height: 100vh;
        width: 100%;
        margin-top: -15%;
        display: flex;
        justify-content: center;
        object-fit: contain;
        border-radius: 6px;
        align-items: center;
        transition: transform 0.3s ease;
    }
}
@media screen and (max-width:760px){
    .seconde-container h1{
        font-size: 40px;
    }
    .seconde-container p{
        font-size: 40px;
    }
    .seconde-container .image-back{
        object-fit: cover;
        width: 90%;
        height: 60%;
        position: absolute;
        z-index: -1;
    }
    .seconde-container:hover{
        transform: none;
    }
    .Image_SC{
        width: 90%;
        align-items: center;
        z-index: 2;
    }
    .imageV{
        width: 110px;
        height: 100px;
        margin-top: 40px;
        margin-left: 5%;
    }
    .seconde-container {
        height: 100vh;
        width: 100%;
        margin-top: -15%;
        margin-bottom: -5%;
        display: flex;
        justify-content: center;
        object-fit: contain;
        border-radius: 6px;
        align-items: center;
        transition: transform 0.3s ease;
    }
}
@media only screen and (max-width:667px){
    .seconde-container h1{
        font-size: 30px;
    }
    .seconde-container p{
        font-size: 35px;
    }
    .seconde-container .image-back{
        object-fit: cover;
        width: 90%;
        height: 50%;
        position: absolute;
        z-index: -1;
    }
    .seconde-container:hover{
        /* transform: none; */
    }
    .Image_SC{
        width: 90%;
        align-items: center;
        z-index: 2;     
    }
    .imageV{
        width: 110px;
        height: 100px;
        margin-top: 40px;
        margin-left: 5%;
    }
    .seconde-container {
        height: 100vh;
        width: 100%;
        margin-top: -25%;
        margin-bottom: -5%;
        display: flex;
        justify-content: center;
        object-fit: contain;
        border-radius: 6px;
        align-items: center;
        transition: transform 0.3s ease;
    }
}
