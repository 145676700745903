.split_right{
    margin-bottom: 22%;
    margin-top: 12%;
}
.split_right{
    justify-content: center;
    align-items: center;    
}
.Split__container__right{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 90%;
    margin-left: 5%;
    margin-bottom: 5%;
    margin-top: 5%;
    align-items: center;
}
@media  screen and (max-width: 960px) {
    .Split__container__right {
        flex-direction: column; /* Stack items vertically */
        align-items: center;
        margin-left: 7%; /* Remove the left margin */

      }
    
      .split-content {
        width: 50%;
        margin-bottom: 20px; /* Adjust the margin as needed */
      }
    
}
@media  screen and (max-width: 760px) {
    .Split__container__right {
        justify-content: center;
        width: 100%; /* Set the width to 100% to center the content */
        margin-left: 7%; /* Remove the left margin */
      }
      .split_right {
        justify-content: center;
        margin-left: 7%; /* Remove the left margin */

        
      }
      .split_right{
        margin-bottom: 2%;
        margin-top: 2%;
    }
}
@media  screen and (max-width: 560px) {
    .Split__container__right {
        justify-content: center;
        width: 50%; /* Set the width to 100% to center the content */
        margin-left: 5%; /* Remove the left margin */
      }
    
      .split_right {
        justify-content: center;
        align-items: center;
      } 
      .split_right{
        margin-bottom: 2%;
        margin-top: 2%;
    }
}