
  .container__card{
    width: 480px; 
    height: 350px;
    margin: 10px;

  }
  .box {
    width: 480px; 
    height: 350px;
    border-radius: 7px;
    background-color: #fff;
    border: 1px solid #ccc;
    transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
    overflow: hidden;
    position: relative;
    left:-4%;
    background-size: cover;
    background-position: center;
}

.content {
    align-items: center;
    justify-content: center;
    height: 100%;
    display: flex; /* Add display: flex to use flexbox properties */
    text-align: center; /* Center-align text */
}
.box2{
  display: none;
  width: 500px; 
  height: 350px;
  border-radius: 7px;
  background-color: #fff;
  border: 1px solid #ccc;
  overflow: hidden;
  position: relative;
  left:-4%;
  background-size: cover;
  align-items: center;
  justify-content: center;
  text-align: center;

}
.card__desc{
  margin:15%; 
  font-weight: bold; /* Set font weight */
  color: black;
  font-size: 20px; 
  font-weight: bold; 
}
.box:hover .box2{
  display: block;
}
.box .card__text {
    margin: 0; /* Remove default margin */
    font-size: 16px; /* Set font size */
    font-weight: bold; /* Set font weight */
    color: #fff; /* Set text color */
}

.box#box1 {
    margin-left: 20px; 
}

.content {
    align-items: center;
    justify-content: center;
    height: 100%;
}
.content p{
  font-size: 20px; 
  font-weight: bold; 
  color: #fff;
}
.box {
    display: inline-block;
}
.card__text{
    font-size: 32px; font-weight: bold; color: #fff;
}
@media  screen and (max-width: 760px) {
  .container__card{
    width: 480px; 
    height: 350px;
    padding-bottom: 10%;
    margin-bottom: 20px;
  }
  .box {
    width: 480px; 
    height: 350px;
    
}
}

@media screen and (max-width: 1400px) {
  .container__card{
    width: 380px; 
    height: 250px;
    padding-bottom: 10%;
    margin-bottom: 20px;
  }
  .box {
    width: 380px; 
    height: 250px;
}
.box2{
  width: 400px; 
  height: 350px;
}
.card__desc{
  margin:15%; 
  font-weight: bold; /* Set font weight */
  color: black;
  font-size: 14px; 
  font-weight: bold; 
}
}
@media screen and (max-width: 1200px) {
  .container__card{
    width: 350px; 
    height: 230px;
    padding-bottom: 10%;
    margin-bottom: 20px;
  }
  .box {
    width: 350px; 
    height: 230px;
}
.box2{
  width: 400px; 
  height: 350px;
}
.card__desc{
  margin:15%; 
  font-weight: bold; /* Set font weight */
  color: black;
  font-size: 12px; 
  font-weight: bold; 
}
}
@media screen and (max-width: 1100px) {
  .container__card{
    width: 280px; 
    height: 200px;
    padding-bottom: 10%;
    margin-bottom: 20px;
  }
  .box {
    width: 280px; 
    height: 200px;
}
.box2{
  width: 300px; 
  height: 210px;
}
.card__desc{
  margin:15%; 
  font-weight: bold; /* Set font weight */
  color: black;
  font-size: 12px; 
  font-weight: bold; 
}
}
@media  screen and (max-width: 960px) {
  .container__card{
    width: 480px; 
    height: 350px;
    padding-bottom: 10%;
    margin-bottom: 20px;
  }
  .box {
    width: 480px; 
    height: 350px;
    
}
.box2{
  width: 500px; 
    height: 350px;
}
.card__desc{
  margin:15%; 
  font-weight: bold; /* Set font weight */
  color: black;
  font-size: 20px; 
  font-weight: bold; 
}
}
@media screen and (max-width: 660px) {
  .container__card{
    width: 270px; 
    height: 200px;
    padding-bottom: 10%;
    margin-bottom: 20px;
  }
  .box {
    width: 270px; 
    height: 200px;
    left:-6%;
}
.box2{
  width: 300px; 
  height: 200px;
  border-radius: 7px;
  background-color: #fff;
  border: 1px solid #ccc;
  overflow: hidden;
  position: relative;
  left:-6%;
  background-size: cover;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.card__desc{
  margin:15%; 
  color: black;
  font-size: 12px; 
  font-weight: bold; 
}
}