.big__dividedcard__item__container{
    padding: 5%;
    line-height: 28px;
}
.big__dividedcard__item__container h1{
    padding-bottom: 2%;
    font-size: 27px;
    font-weight: 200;
    line-height: 30px;
}
.big__dividedcard__item__container p{
    padding-bottom: 2%;
    font-size: 17px;
    color: #afafaf;
    font-weight: 200;
}
@media screen and (max-width:660px){
    .big__dividedcard__item__container h1{
        padding-bottom: 2%;
        font-size: 20px;
        font-weight: 200;
        line-height: 30px;
    }
    .big__dividedcard__item__container p{
        padding-bottom: 2%;
        font-size: 12px;
        color: #afafaf;
        font-weight: 200;
    }
}