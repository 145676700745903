.cards {
    
    background: rgb(242, 253, 253);
    width: 100%;
    
  }
  
  .cards__container__main {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 40%;
    margin: 0 auto;
  
  }
  
  .cards__wrapper {
    position: relative;
    display: flex;
    flex: 1;
    border-radius: 10px;
    margin-right: 1%;
    margin-bottom: 10%;
    
  }

  @media  screen and (max-width: 1400px) {
    .cards__wrapper{
        display: flex;
        margin-bottom: 20px;
    }
  }
  @media  screen and (max-width: 960px) {
    .cards__wrapper{
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }
    .cards__title h1{
        text-align: center;
        font-size: 48px; 
        font-weight: bold;
      }
      .cards__title p{
        font-size: 17px;
        text-align: center;
        color: #afafaf;
      }
    
  }
  
  
  


    