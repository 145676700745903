.divided__card__Cont{
    text-decoration: none;
    display: flex;
    flex-wrap: wrap;
    justify-content:space-around ;
    align-items: center;
    margin-left: 5%;
    padding-top: 5%;
    padding-bottom: 5%;
    width: 90%;
    text-decoration: none;
    height: 100%;
}
.Link_divided__card{
    text-decoration: none;
}