.container__icons{
 
    justify-content: center;
    text-align: center;
    align-items: center;
}
.Section__icons__list{
    justify-content: center;
    align-items: center;
    width:100%;
}
.Section__icons__container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.Section__single__infos{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 300px;
    padding: 2%;
    
}

.Section__title__infos{
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 10%;
    color: black;
}
.Section__infos__icon i{
    font-size: 30px;
    font-size: 28px;
    width: 70px;
    height: 70px;
    line-height: 70px;
    border-radius: 50%;
    text-align: center;
    color: #3cc2bb;
    background-color: rgb(10 191 83 / 17%);
}
.Section__text__infos{
    margin-top: 10%;
    width: 100%;
}