.Split__img__sup{
    border-radius: 7px;
    width: 700px;
    height: 450px;
    justify-content: center;
    align-items: center;
    
}
.Split__img__sup img{
    border-radius: 7px;
}
.Split_img1_container__right img{
    width: 600px;
    height: 100%;
    box-shadow: 0 25px 98px 0 rgba(0, 0, 0, 0.365);
    
}
.Split_img2_container__right img{
    width: 250px;
    height: 100%;
    box-shadow: 0 25px 98px 0 rgba(0, 0, 0, 0.365);
   
}
.Split_img1_container__right{
    position: absolute;
    margin-left: 7%;
    z-index: 0;
}
.Split_img2_container__right{
    position: absolute;
    z-index: 2;
    margin-top: 17%;
    
}
@media  screen and (max-width: 1400px) {
    .Split__img__sup{
        border-radius: 7px;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-bottom: 5%;
    }
    .Split_img1_container__right{
        width: 450px; 
        margin-top: 5%;
    }
    .Split_img1_container__right img{
        width: 400px;
        border-radius: 7px;
        margin-left: 0%;
    }
 
}
@media  screen and (max-width: 1200px) {
    .Split__img__sup{
        border-radius: 7px;
        width: 100%;
        height: 250px;
        align-items: center;
        justify-content: center;
    }
    .Split_img1_container__right{
        width: 350px; 
    }
    .Split_img1_container__right img{
        width: 350px;
        border-radius: 7px;
        margin-top: 0%;
        margin-left: 0%;
    }
    .Split_img2_container__right{
        display: none;
        margin-top: 0%;
        margin-left: 0%;
    }
  
}
@media  screen and (max-width: 970px) {
    .Split_img1_container__right{
        width: 300px;
        margin-left: -24%; 
    }
  
}
@media  screen and (max-width: 670px) {
    .Split__img__sup{
        border-radius: 7px;
        width: 50%;
        height: 250px;
        align-items: center;
        justify-content: center;
        margin-bottom: 2%;
    }
    .Split_img1_container__right{
        width: 300px;
        margin-left: -24%; 
    }
    .Split_img1_container__right img{
        width: 300px;
        border-radius: 7px;
        margin-top: 0%;
    }
    .Split_img2_container__right{
        display: none;
        margin-top: 0%;
        margin-left: 0%;
    }
  }