*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}
.navbar {
    background: #fff;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
  }
  
  .navbar-logo {
    color: black;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }
  
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(7, auto);
    grid-gap: 7px;
    list-style: none;
    text-align: center;
    width: 66vw;
    justify-content: end;
    margin-right: 2rem;
  }
  
  .nav-item {
    height: 15px;
  }
  
  .nav-links {
    color: #9C9C9C;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.9rem 0.5rem;
    height: 100%;
    font-size: 17px;
    font-weight: 600;
  }
  
  .nav-links:hover {
    color: #66e8dc;
    transition: all 0.8s ease;
  }
  .nav-menu .nav-item:first-child .nav-links {
    color: #66e8dc; /* Set the color for the first item */
  }
  .nav-menu .nav-item:last-child {
    padding-left: 50px;
  }
  .fa-globe {
    color: #9C9C9C; 
    margin-right: 5px;
    font-size: 15px;
  }
  
  .fa-bars {
    color: #66e8dc;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
      
    }
    .navbar-logo {
      color: black;
      justify-self: start;
      margin-left: -40px;
      cursor: pointer;
      text-decoration: none;
      font-size: 2rem;
      display: flex;
      align-items: center;
    }
    .navbar {
        background: #FFF;
        width: 100%;
    }
    .nav-menu {
      display: flex;
      color: #0c0c0c;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 65px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #242222;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
      height: 100vh;
    }
  
    .nav-links {
    color: #fff;
      text-align: center;
      justify-content: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
    .nav-menu .nav-item:last-child {
        display: block;
        text-align: center;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        text-decoration: none;
        font-size: 1.5rem;
        background-color: transparent;
        padding: 14px 20px;
        transition: all 0.3s ease-out;
      }
    .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
    }
    .nav-menu .nav-item:first-child:hover .nav-links {
        color: #66e8dc; /* Set the color for the first item */
      }
      
      .nav-menu .nav-item:first-child .nav-links {
        color: #fff; /* Set the color for the first item */
      }
      
    .nav-item {
        height: 100px;
        margin-top: 1%;
      }
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #66e8dc;
      font-size: 2rem;
    }
    .fa-globe{
        color: #fff;
    }
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 30px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }
    .btn--connexion{
        padding: 10px 30%;
    }
    .btn--connexion:hover{
        color: #242424;
        background-color: #fff;
    }
    .btn--connexion:hover  .fa-globe{
        color: #242424;
        
    }
    .btn--outline{
        
        color: #fff;
    }
  }
.combo{
  /* width: 30px; */
  border: none;
  outline: none;
  color: #9C9C9C
}
