.Contact__footer__container{
    width: 100%;
   
}
.Contact__footer{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.contact__maps{
    padding-left: 2%;
    margin-top: 2%;
}
.contact__button{
    background-color: var(--primary);
    color:#fff;
    border: 1px solid var(--primary);
    padding: 15px 17px;
    font-size:15px;
    border: 2px solid #66e8dc;
    margin-left: 82%;
    border-radius: 6px;
}
.contact__maps iframe{
    border-radius: 6px;
    width: 600px;
    height: 400px;
    border: none;
}
.contact__form{
    padding-left: 2%;
    align-items: center;
    justify-content: center;
    margin-top: 2%;
}
.contact__form form{
    display: flex;
    flex-direction: column;
}
.contact__form__input{
    width: 720px;
    outline: none;
    font-size: 16px;
    line-height: 28px;
    padding: 14px 20px;
    margin-bottom: 20px;
    color: #060606;
    text-align: left;
    background: #fafafa;
    display: grid;
    border: 2px solid #eee;
    border-radius: 6px;
    font-weight: 100;
}
.contact__text{
    width: 720px;
    height: 150px;
    outline: none;
    font-size: 16px;
    line-height: 28px;
    padding: 14px 20px;
    margin-bottom: 20px;
    color: #060606;
    text-align: left;
    background: #fafafa;
    display: grid;
    border: 2px solid #eee;
    border-radius: 6px;
    font-weight: 100;
}
@media screen and (max-width:960px){
    .contact__button{
        background-color: var(--primary);
        color:#fff;
        border: 1px solid var(--primary);
        padding: 15px 17px;
        font-size:15px;
        border: 2px solid #66e8dc;
        margin-left: 0;
        
    }
}
@media screen and (max-width:760px){
    .contact__button{
        background-color: var(--primary);
        color:#fff;
        border: 1px solid var(--primary);
        padding: 15px 17px;
        font-size:15px;
        border: 2px solid #66e8dc;
        width: 200px;
        text-align: center;
    }
    .contact__form__input{
        width: 400px;
    }
    .contact__text{
        width: 400px;
    }
    .contact__maps iframe{
        border-radius: 6px;
        width: 350px;
        height: 300px;
        border: none;
    }
}
@media screen and (max-width:560px){
    .contact__button{
        background-color: var(--primary);
        color:#fff;
        border: 1px solid var(--primary);
        padding: 15px 17px;
        font-size:15px;
        border: 2px solid #66e8dc;
        width: 200px;
        text-align: center;
    }
    .contact__form__input{
        width: 300px;
    }
    .contact__text{
        width: 300px;
    }
    .contact__maps iframe{
        border-radius: 6px;
        width: 300px;
        height: 250px;
        border: none;
    }
}