* {
  box-sizing: border-box;
  margin: 0;
}

:root {
  --heights: 100vh;
  --widths: 100%;
}
.container{
  display: flex;
  flex-flow: row ;
  justify-content: space-between;
  align-items: center;
}

.slider-container {
  height: 100vh;
  width: 100%;
  margin-top: -5%;
  display: flex;
  flex-direction: column;
  object-fit: contain;
  justify-content: center;
}
.image__V{
  position: relative; 
  margin-left: 7%;
  top:14.5%; 
  margin-top: 80px; 
  width: 170px; 
  height: 140px;
}
.active {
  display: inline-block;
}

.inactive {
  display: none;
}

.slides {
  height: var(--heights);
  width: var(--widths);
  position: relative;
}

.slide-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
}


.slide-title{
  color: #fff;
    font-size: 55px;
    margin-left: 8%;
    /* margin-top: 2%; */
    margin-top: 9%;
    z-index: 66;
}

.slide-text {
    color: #fff;
    font-size: 70px;
    margin-left: 8%;
    margin-top: 2%;
    z-index: 66;
}
.container__button{
  width: 350px;
  height: 70px;
  justify-content: left;
  align-items: center;
  display: flex;
  /* background-color: red; */
  margin-left: 8%;
  margin-top: 2%;
  z-index: 66;
  /* position: absolute;
  right: 0; */
}

.gradient-button {
  background: linear-gradient(to right, #5aeae7, #66e8dc);
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  padding-left: 50px;
  padding-right: 50px;
  
}

.gradient-button:hover {
  background: linear-gradient(to right,  #5aeae7, #66e8dc); 
}
.slide_number1{
  background-color: #efefef; 
  border-radius: 100%; 
  width: 25px;
  opacity: 0.7; 
  height: 25px; 
  justify-content: center; 
  align-items: center;
  display: flex;
  font-size: 16px;
  text-align: center;
  color: rgb(58, 58, 58);
  
}
.slide_number2{
  background-color: #efefef; 
  border-radius: 100%; 
  width: 25px;
  opacity: 0.7; 
  height: 25px; 
  justify-content: center; 
  align-items: center;
  display: flex;
  font-size: 16px;
  text-align: center;
  color: rgb(58, 58, 58);
}
.slide_number3{
  background-color: #efefef; 
  border-radius: 100%; 
  width: 25px;
  opacity: 0.7; 
  height: 25px; 
  justify-content: center; 
  align-items: center;
  display: flex;
  font-size: 16px;
  text-align: center;
  color: rgb(58, 58, 58);
}
.slide_number4{
  background-color: #efefef; 
  border-radius: 100%; 
  width: 25px;
  opacity: 0.7; 
  height: 25px; 
  justify-content: center; 
  align-items: center;
  display: flex;
  font-size: 16px;
  text-align: center;
  color: rgb(58, 58, 58);
}
.all-dots {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  top: 92%;
  justify-content: center;
  z-index: 200;
  
}

.dot {
  cursor: pointer;
  height: 1rem;
  width: 1rem;
  margin: 0px 3px;
  background-color: #66e8dc;
  border-radius: 50%;
  display: inline-block;
}

.slider-container:hover .dot:hover {
  background-color: #66e8dc;
}
.slider-container:hover .dot{
  background-color: #c3c3c3;
}
.slider-container:hover .active-dot{
  background-color: #66e8dc;
}

.play-pause {
  color: black;
}

.slider_number-container {
  position: absolute;
  top: 45%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 4%;
}

.slider_number-container .number-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #fff;
  margin-top: 120px;
}
.slider_number-container .number-box hr {
    
  border: none;
  width: 2px;
  height: 100px;
  background-color: #bcbcc0;
}

@media screen and (max-width:960px){
  .container{
    display: flex;
    flex-flow: row ;
    justify-content: space-between;
    align-items: center;
  }   
  .stacked-images1{
    /* display: none; */
  }
  .slide-title{
      font-size: 50px;
      margin-left: 100px;
      margin-top: 100px;
  }
  .slide-text{
    font-size: 50px;
    margin-left: 100px;
  }
  .slider-container {
      box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.2);
      height: 100vh;
      width: 100%;
     
  }
  .container__button {
    margin-left: 100px;
  }

}
@media screen and (max-width:760px){
  .container{
    display: flex;
    flex-flow: row ;
    justify-content: space-between;
    align-items: center;
  }   
  .stacked-images1{
    display: none;
  }
  .slide-title{
      font-size: 30px;
      margin-left: 80px;
      margin-top: 150px;
  }
  .slide-text{
    font-size: 30px;
    margin-left: 80px;
  }
  .slider-container {
      box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.2);
      height: 100vh;
      width: 100%;
     
  }
  .container__button {
    margin-left: 80px;
  }
}
@media only screen and (max-width:667px){
  .container{
    display: flex;
    flex-flow: row ;
    justify-content: space-between;
    align-items: center;
  }   
  .image__V{
    position: relative; 
    margin-left: 7%;
    top:14.5%; 
    margin-top: 80px; 
    width: 130px; 
    height: 100px;
  }
  .stacked-images1{
    display: none;
  }
  .slide-title{
      font-size: 30px;
      margin-left: 80px;
      margin-top: 150px;
  }
  .slide-text{
    font-size: 30px;
    margin-left: 80px;
  }
  .slider-container {
      box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.2);
      height: 100vh;
      width: 100%;
     
  }
  .container__button {
    margin-left: 80px;
  }
  .all-dots {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    top: 92%;
    justify-content: center;
    z-index: 200;
    
  }
}
