.Split__solution__container__in {
    border-radius: 7px;
    width: 650px;
    height: 450px;
    position: relative;
}

.Split__solution__container__in .Split__Section__img1__in {
    position: absolute; /* Change to absolute */
    width: 500px;
    height: 300px;
    border-radius: 7px;
}
.Split__solution__container__in .Split__Section__img2__short__in{
    position: absolute; /* Change to absolute */
    width: 280px;
    height: 200px;
    border-radius: 7px;
    padding-top: 30%;
    padding-left: 55%;
}

.Split__solution__container__in .Split__Section__img1__in {
    z-index: 1; /* Set a lower z-index for the first image */
}

.Split__solution__container__in .Split__Section__img2__short__in {
    z-index: 2; /* Set a higher z-index for the second image to make it appear on top */
}

.Split__solution__container__in .Split__Section__img1__in img {
    position: absolute;
    width: 100%; /* Use 100% to make sure the image fills its container */
    height: 100%;
    border-radius: 7px;
}
.Split__solution__container__in .Split__Section__img2__short__in img{
    position: absolute;
    width: 100%; /* Use 100% to make sure the image fills its container */
    height: 100%;
    border-radius: 7px;

}
@media screen and (max-width: 1200px) {
    .Split__solution__container__in {
        width: 400px;
        height: 250px;
        
    }

    .Split__solution__container__in .Split__Section__img1__in {
        width: 400px;
        height: 220px;
        
    }

    .Split__solution__container__in .Split__Section__img2__short__in {
        width: 200px;
        height: 150px;
        padding-top: 30%;
        padding-left: 60%;
    }
}
@media screen and (max-width: 1010px) {
    .Split__solution__container__in {
        width: 350px;
        height: 300px;
        margin-top: 10%;
        padding-left: 5%;
    }

    .Split__solution__container__in .Split__Section__img1__in {
        width: 300px;
        height: 200px;
       
    }

    .Split__solution__container__in .Split__Section__img2__short__in {
        width: 200px;
        height: 120px;
        padding-top: 35%;
    }
}
@media screen and (max-width: 799px) {
    .Split__solution__container__in {
        width: 300px;
        height: 250px;
        margin-bottom: 10%;
    }

    .Split__solution__container__in .Split__Section__img1__in {
        width: 250px;
        height: 150px;
    }

    .Split__solution__container__in .Split__Section__img2__short__in {
        width: 150px;
        height: 70px;
        padding-top: 35%;
        padding-left: 50%;

    }
}