*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;  
}
.mahaam{
  background-color: rgb(242, 253, 253);
  padding-top: 5%;
}
.title__Section{
  margin: 5%;
  margin-bottom: -2%;
  display: flex;
  flex-direction: column;
  margin-top: -5% !important;
  justify-content: center;
}
.title__Section h1{
  color: rgb(60, 194, 187);
  font-size: 50px;
  font-weight: 200;
  margin-left: 5%;

}
.butt{
  width: 100%;
  margin-bottom: 5%;
}
.butt1{
  width: 100%;

}
.butt1 Button{
  margin-left: 7%;
}
.txt{
  width: 100%;
}
.title__Section .butt Button{
  float: right;
  margin-right: 5%;
}
@media screen and (max-width:960px){
  .title__Section{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
   
  }
  .title__Section h1{
    color: rgb(60, 194, 187);
    font-size: 50px;
    font-weight: 200;
    text-align: center;
  }
  .butt{
    padding-left: 2%;
    margin-bottom: 5%;
    align-items: center;
  }
  .butt1{
    
    justify-content: center;
    align-items: center;
  
  }
  .butt1 Button{
   
  }
  .txt{
    float: left;
  }
  .title__Section .butt Button{
    float: left;
    margin-left: 5%;
  }
}
