.DC__container{
  text-decoration: none;
  background-color: white;
  border-radius: 7px;

}
.divided__card {
    text-decoration: none;
    box-shadow: 0 25px 98px 0 rgba(0, 0, 0, 0.068);
    transition: 0.3s;
    border-radius: 7px;
    width: 400px;
    height: 100%;
    border: .1px solid rgba(128, 128, 128, 0.386);
    align-items: center;
    justify-content: center;
    margin-top: 6%;
    background-color: white;
  }
  .divided__card img{
    width: 100%;
    border-radius: 5px 5px 0 0;
  }
  /* On mouse-over, add a deeper shadow */
  .divided__card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    transform: scale(1.05);
  }
  
  /* Add some padding inside the card container */
  .divided__card__container {
    text-decoration: none;
    border-radius: 7px;
    align-items: center;
    justify-content: center;
    padding: 5%;
    background-color: white;
  }
  .divided__card__container h4{
    color: rgb(7, 7, 7); 
    font-weight: bold;
    padding-bottom: 5%;
  }
  .divided__card__container p{
    font-size: 17px;
    line-height: 28px;
    color: #afafaf;
    font-weight: 500;
  }
  @media  screen and (max-width: 800px) {
    .divided__card{
        width: 320px;
        height: 100%;
    }
  }