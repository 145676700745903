
.big__dividedcard__container{
    width: 620px;
    
}
.big__dividedcard__container{
    text-decoration: none;
    box-shadow: 0 25px 98px 0 rgba(0, 0, 0, 0.068);
    transition: 0.3s;
    border-radius: 7px;
    height: 100%;
    border: .1px solid rgba(128, 128, 128, 0.386);
    align-items: center;
    justify-content: center;
    margin-top: 6%;
    background-color: white;
}
.big__dividedcard__container img{
    width: 100%;
    border-radius: 5px 5px 0 0;
  }
  @media screen and (max-width:960px){
    
   
.big__dividedcard__container{
    width: 420px;
    
}
}
@media screen and (max-width:660px){
    
    .big__dividedcard__container{
        width: 300px;
        text-align: center;
    }
}