.Split__text_container__black{
    width: 550px;
}
.Split__title__black{
    color: black;
    font-size: 30px;
    line-height: 45px;
    font-weight: 700;
   
}
.Split__desc__black{
    color: gray;
    font-size: 15px;
    font-weight: 200;
}
@media  screen and (max-width: 1400px) {
    .Split__text_container__black{
        width: 400px;
        margin-top: 2%;
    }
  }
  @media  screen and (max-width: 1200px) {
    .Split__text_container__black{
        width: 350px;
        margin-top: 5%;
    }
  }
  @media  screen and (max-width: 960px) {
    .Split__text_container__black{
        padding-left: 5%;
    }
}
@media  screen and (max-width: 799px) {
    .Split__text_container__black{
        justify-self: center;
        margin-top:5%;
        text-align: center;
    }
    .Split__text_container__black{
        width: 300px;
        margin-top: 5%;

    }
    .Split__title__black{
        color: black;
        font-size: 25px;
        line-height: 35px;
        font-weight: 700;
        margin-bottom: 5%;
    }
    .Split__desc__black{
        color: gray;
        font-size: 15px;
        font-weight: 200;
    }
}