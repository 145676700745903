.BDC__Container{
    padding: 10%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
}
.BDC__SMALL{
    padding-top: 4%;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width:1400px){
    
    .BDC__Container{
        padding: 10%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        
    }
}
@media screen and (max-width:960px){
    
    .BDC__Container{
        padding: 10%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}