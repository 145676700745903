.Split__img__sup{
    border-radius: 7px;
    width: 700px;
    height: 450px;
}
.Split__img__sup img{
    border-radius: 7px;
}
.Split_img1_container img{
    width: 600px;
    height: 100%;
    box-shadow: 0 25px 98px 0 rgba(0, 0, 0, 0.365);
}
.Split_img2_container img{
    width: 400px;
    height: 100%;
    box-shadow: 0 25px 98px 0 rgba(0, 0, 0, 0.365);
}
.Split_img1_container{
    position: absolute;
    z-index: 0;
}
.Split_img2_container{
    position: absolute;
    margin-top: 17%;
    margin-left: 22%;
    z-index: 2;
}
@media  screen and (max-width: 1500px) {
    .Split__img__sup{
        border-radius: 7px;
        width: 450px;
    }
    .Split_img1_container{
        width: 450px; 
        margin-top: 5%;
    }
    .Split_img1_container img{
        width: 450px;
        border-radius: 7px;
    }
    .Split_img2_container img{
        width: 300px;
        height: 100%;
        box-shadow: 0 25px 98px 0 rgba(0, 0, 0, 0.365);
    }
}
@media  screen and (max-width: 1200px) {
    .Split__img__sup{
        border-radius: 7px;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-left: 7%;
    }
    .Split_img1_container{
        width: 400px; 
        margin-top: 5%;
    }
    .Split_img1_container img{
        width: 400px;
        border-radius: 7px;
    }
    .Split_img2_container{
        display: none;
    }
}
@media  screen and (max-width: 670px) {
    .Split__img__sup{
        border-radius: 7px;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-left: 7%;
    }
    .Split_img1_container{
        width: 300px; 
        margin-top: 5%;
    }
    .Split_img1_container img{
        width: 300px;
        border-radius: 7px;
        margin-left: 4%;

    }
    .Split_img2_container{
        display: none;
    }
  }