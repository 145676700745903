.Split_video_container{
    border-radius: 7px;
    width: 600px;
    height: 400px;
    margin-top: 20px;
}
.Split_video_container video{
    width: 600px;
    height: 400px;
    border-radius: 7px;
}
.Split_video_container .video__rad{
    border-radius: 7px;
}
.video__rad{
    border-radius: 7px;
}

.Split_video_container:hover video{
    transform: scale(1.05);
    transition: .3s ease-in-out;
    border-radius: 7px;
}

@media  screen and (max-width: 1400px) {
    .Split_video_container{
        width: 450px; 
        height: 350px;
        margin-top: 5%;
        border-radius: 7px;
    }
    .Split_video_container video{
        width: 450px;
        height: 350px;
        border-radius: 7px;
        
    
    }
}
@media  screen and (max-width: 1200px) {
    .Split_video_container{
        width: 350px; 
        height: 250px;
        margin-top: 5%;
        border-radius: 7px;
    }
    .Split_video_container video{
        width: 350px;
        height: 250px;
        border-radius: 7px;

    }
}
@media  screen and (max-width: 670px) {
    .Split_video_container{
        width: 300px; 
        height: 250px;
        margin-top: 5%;
    }
    .Split_video_container video{
        width: 300px;
        height: 250px;
        border-radius: 7px;

    }
}