
.container__login__contact {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 200;
  }
  
  .hero-container__Login__contact {
    background-color: rgba(255, 255, 255, 0.199); /* Add a semi-transparent white background for better readability */
    padding: 20px;
    border-radius: 10px;
    font-weight: 200;
    width: 700px;
    height: 400px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.315); /* Add a subtle box shadow for a card-like appearance */
  }
  
  .form__contact {

    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 200;
  }
  
  .form__contact__label {
    margin: 10px 0;
    font-weight: 200;
  }
  
  .form__contact__input {
    font-weight: 200;
    width: 500px;
    padding: 17px;
    margin-top: 7px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.491);
    border: 1px solid rgba(255, 255, 255, 0.854) ;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); 
  }
  .form__contact__input_M{
    font-weight: 200;
    height: 100px;
    width: 500px;
    padding: 17px;
    margin-top: 7px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.491);
    border: 1px solid rgba(255, 255, 255, 0.854) ;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); 
  }
  .form__contact__input:focus {
    outline: none; /* Remove the default focus outline */
    border-color: rgba(52, 152, 219, 0.441); /* Change the border color when the input is in focus */
    box-shadow: 0 0 10px rgba(52, 152, 219, 0.215); /* Add a box shadow when the input is in focus */
}

  
  .error {
    color: red;
    margin-top: 5px;
    font-size: 14px;
    font-weight: 200;
    background-color: rgba(248, 143, 143, 0.703) ;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid rgba(255, 138, 138, 0.1);
}

  .form__contact__butt {
    margin-top: 10px;
    padding: 17px 17px;
    background-color: rgba(255, 255, 255, 0.368);
    color: white;
    border: none;
    border-radius: 7px;
    font-weight: 200;
    font-size: 17px;
    cursor: pointer;
    width: 500px;
    transition: background-color 0.3s ease;
  }
  
  .form__contact__butt:hover {
    background-color: #297fb968;
  }
  .title__login__contact{
    text-align: center;
  }
  .title__login__contact h1{
    padding-top: 5%;
    font-weight: 200;
    font-size: 50px;
    color: #fff;
  }
  @media screen and (max-width:960px){
    .hero-container__Login__contact{
      width: 350px;
    }
    
  .form__contact__input {
    font-weight: 200;
    width: 300px;
    padding: 17px;
    margin-top: 7px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.491);
    border: 1px solid rgba(255, 255, 255, 0.854) ;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); 
  }
.form__contact__butt {
  margin-top: 10px;
  padding: 17px 17px;
  background-color: rgba(255, 255, 255, 0.368);
  color: white;
  border: none;
  border-radius: 7px;
  font-weight: 200;
  font-size: 17px;
  cursor: pointer;
  width: 300px;
  transition: background-color 0.3s ease;
}

}