.cards__title{
    margin-bottom: 3%;
    /* margin-top: -15%; */
  }
.cards__title h1{
    text-align: center;
    font-size: 40px; 
    font-weight: bold;
    padding: 1%;
}

.cards__title p{
    font-size: 20px;
    text-align: center;
    color: #afafaf;
    font-weight: 200;
}