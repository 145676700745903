.split__solution{
    justify-content: center;
    align-items: center;
    margin: 20px;


}

.split__solution__container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 90%;
    margin-left: 5%;
    margin-bottom: 5%;
    margin-top: 5%;
    align-items: center;
}
@media  screen and (max-width: 1400px) {
    .split__solution__container{
        width: 85%;
    }
  }
@media  screen and (max-width: 960px) {
    .split__solution__container{
        justify-content: center;
    }
}