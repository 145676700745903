

.hero-Fixed__Container {
        
        height: 500px;
        position: relative;
}

.Fixed__Container__h {
    padding: 5%;
    font-size: 17px;
    text-align: center;
    color: white; /* Set your desired text color */
}
.Fixed__Container__p {
    width: 100%;
    padding-right: 15%;
    padding-left: 15%;
    line-height: 28px;
    color: white; /* Set your desired text color */
}
.Fixed__Container__p p{
    font-size: 17px;
    font-weight: 100;
    padding-bottom: 6%;
}
.Fixed__Container__p h3{
    font-size: 25px;
    font-weight: 900;
    padding-bottom: 1%;
}
.Fixed__Container__p .Fixed__text1{
    font-size: 19px;
    font-weight: 100;
    padding-bottom: 1%;
}
@media only screen and (max-width:667px){
    .Fixed__Container__p {
        padding-right: 15%;
        padding-left: 15%;
        line-height: 28px;
        color: white; 
        text-align: center;/* Set your desired text color */
    }
    .hero-Fixed__Container{
        height: 800px;
    }
}