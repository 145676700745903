.Contact__header{
    margin: 5%;
    display: block;
    align-items: center;
    justify-content: center;
}
.Contact__big__title{
    padding-left: 1%;
   
}
.Contact__text{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 150px;
}
.Contact__title{
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 4px;
    color: gray;
}
.p__home{
    font-weight: bold;
    font-size: 15px;
    color: gray;
}
.p__home__i{
    font-weight: 5000;
    font-size: 13px;
    margin-top: 1%;
    color: gray;
}
.p__contact{
    font-size: 15px;
}
.Contact__icons__list{
    width: 100%;
    justify-content: center;
    margin-top: 5%;
    
}
.Contact__icons__container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.Contact__single__infos{
    display: flex;
    flex-wrap: wrap;
    width: 300px;
    padding-top: 5%;
}
.Contact__desc__infos{
    font-size: 14px;
    line-height: 28px;
    color: #afafaf;
    font-weight: 100;
    text-decoration: none;
}

.Contact__title__infos{
    font-size: 17px;
    margin-bottom: 10%;
}
.Contact__infos__icon i{
    font-size: 30px;
    font-size: 28px;
    width: 70px;
    height: 70px;
    line-height: 70px;
    border-radius: 50%;
    text-align: center;
    color: #3cc2bb;
    background-color: rgb(10 191 83 / 17%);
}
.Contact__text__infos{
    margin-left: 10%;

    width: 50%;
}