:root{
    --primary: #66e8dc;
}
*{
    font-weight: 600;
}
.btn {
    padding: 8px 20px;
    border-radius:2px;
    outline:none;
    cursor:pointer;
}

.btn--primary {
    background-color: var(--primary);
    color:#fff;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border:none;
    transition: all 0.3s ease-out;
    
}
.btn--Translation{
    background-color: transparent;
    color: #66e8dc;
    padding: 8px 20px;
    border:1px solid #66e8dc;
    transition: all 0.3s ease-out;
    
}

.btn--medium {
    padding: 8px 20px;
    font-size:20px;
    border: 2px solid #66e8dc;
    border-radius: 7px;
}
.btn--LearnMore{
    padding: 10px 60px;
    font-size:24px;
    border: 2px solid #66e8dc;
    border-radius: 7px;
    font-size: 200;
}
.btn--LearnMore:hover{
    border: 2px solid #8eeae0;
    border-radius: 7px;
    font-size: 200;
    background-color: #8eeae0;
}
.btn--connexion{
    padding: 0px 0px;
    font-size:17px;
    color: #242424;
    font-weight: 600;
}
.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}
.btn--connexion:hover{
    color: #66e8dc;
}
.btn--medium:hover, .btn--large:hover {
    background: transparent;
    color: #66e8dc;
    border: 2px solid #66e8dc;
    transition: all 0.3s ease-out;
}
.btn--ScrollUp{
    position: fixed;
    bottom: 90px;
    right: 15px;
    z-index: 9;
    font-size: 16px;
    border: none;
    outline: none;
    cursor: pointer;
    color: #060606;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -o-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    padding: 0;
}
