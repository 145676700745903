.faq__header{
    width: 700px;
    display: flex;
    flex-direction: column;
    padding-bottom: 4%;
    padding-top: 3%;
}
.faq__header h1{
    padding-bottom: 2%;

}
.faq__header p{

    color: #afafaf;
    font-weight: 200;
    font-size: 22;
    line-height: 25px;
}
.faq__container{
    width: 650px;
    display: flex;
    flex-direction: column;
    padding-bottom: 4%;
    padding-top: 3%;
    border-bottom: 1px solid #afafaf53;
    cursor: pointer;
    
}
.question{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.answer{
    max-height: 0;
    overflow: hidden;
    transition: max-height 1.2s ease;
}
.faq__container.active  .answer{
    max-height: 300px;
    animation: fade 1s ease-in-out;
}
.faq__title{
    
}
.answer p{
    padding-top: 2%;
    color: #afafaf;
    font-weight: 200;
}
.faq__container.active  svg{
   transform: rotate(-180deg);
}
svg{
    transition: transform 0.7s ease-in;
}
@keyframes fade {
    from{
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}
@media  screen and (max-width: 1400px) {
    .faq__header{
        width: 450px;
    }
    .faq__container{
        width: 450px;
    }
  }
  @media  screen and (max-width: 760px) {
    .faq__header{
        width: 300px;
    }
    .faq__container{
        width: 300px;
    }
  }