.list__item__cont{
    text-decoration: none;
    display: flex;
    flex-wrap: wrap;
    justify-content:space-around ;
    align-items: center;
    margin: 5%;
    margin-top: 0 !important;
    padding-top: 5%;
    width: 90%;
}
