.Split_img_container{
    border-radius: 7px;
    width: 650px;
    height: 450px;
}
.Split_img_container img{
    width: 650px;
    height: 450px;
    border-radius: 7px;

}
.Split_img_container:hover img{
    transform: scale(1.05);
    transition: .3s;
}
@media  screen and (max-width: 1400px) {
    .Split_img_container{
        width: 450px; 
        height: 350px;
        margin-top: 5%;
    }
    .Split_img_container img{
        width: 450px;
        height: 350px;
        border-radius: 7px;
    
    }
}
@media  screen and (max-width: 1200px) {
    .Split_img_container{
        width: 350px; 
        height: 250px;
        margin-top: 5%;
    }
    .Split_img_container img{
        width: 350px;
        height: 250px;
        border-radius: 7px;

    }
}
@media  screen and (max-width: 670px) {
    .Split_img_container{
        width: 300px; 
        height: 250px;
        margin-top: 5%;
    }
    .Split_img_container img{
        width: 300px;
        height: 250px;
        border-radius: 7px;

    }
  }