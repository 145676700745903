.Split__text__color{
    width: 650px;
}
.Split__title__color{
    color: #3cc2bb;
    font-size: 35px;
    line-height: 45px;
    font-weight: 700;
    margin-bottom: 5%;
}
.Split__desc__color{
    color: gray;
    font-size: 20px;
    font-weight: 200;
}
.split__but{
    margin-top: 5%;

}
@media  screen and (max-width: 1400px) {
    .Split__text__color{
        width: 450px;
        margin-top: 2%;
    }
  }
  @media  screen and (max-width: 1200px) {
    .Split__text__color{
        width: 350px;
        margin-top: 5%;
    }
  }
  @media  screen and (max-width: 960px) {
    .Split__container{
        text-align: center;
        margin-top:5%;
    }
}