.footer-container {
    background-color: #272733;
    padding: 4rem 0 2rem 0;
    padding: 20px ; 
    color: #fff;
}
.footer-A{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 10%;
    margin-right: 10%;
}
.footer-subscription{
    display: flex;
    flex-direction: column;
    width: 60%;
}
.footer-links{
    width: 60%;
}
.footer-description{
    width: 100%;
    color: #fff;
}
.footer-description p{
    width: 100%;
    padding: 20px ;  
}
.footer-link-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}
.footer-link-items h3{
    margin-bottom: 20%;
}

.footer-link-items{
    margin-bottom: 10%;
    padding: 20px;
    display: flex;
    flex-direction: column;
}
.links-foot{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.link{
    color: #fff;
    text-decoration: none;
    padding-bottom: 10px;
}
.link:hover{
    color: #fff;
    text-decoration: underline;
}
.footer-copyright{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
}
.footer-icons{
    padding: 20px;
}
.footer-icons i {
    cursor: pointer;
}
.footer-icons  .fa-facebook-f{
    background-color: #fff;
    margin-right: 10px;
    color: #272733;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 70px;
}
.footer-icons .fa-twitter{
    background-color: #fff;
    margin-right: 10px;
    color: #272733;
    padding: 10px;
    border-radius: 70px;
}
.footer-icons .fa-google-plus-g{
    background-color: #fff;
    margin-right: 10px;
    color: #272733;
    padding: 10px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 70px;
}
.footer-icons .fa-instagram{
    background-color: #fff;
    margin-right: 10px;
    color: #272733;
    padding: 10px;
    border-radius: 70px;
}
.footer-icons i:hover{
    background-color: #66e8dc;
    animation: spin 0.3s linear 1;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
@media screen and (max-width:760px){
    .footer-container {
        background-color: #272733;
        padding: 4rem 0 2rem 0;
        padding: 20px ; 
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        color: #fff;
    }
    .footer-A{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        /* margin-left: 10%; */
        margin-right: 10%;
    }
    .footer-description{
        width: 170%;
        color: #fff;
        text-align: center;
    }
    .footer-description p{
        width: 100%;
        padding: 20px;
        padding-left: 0%;  
        padding-right: 0%;  
    }
    .links-foot{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .footer-link-items{
        padding: 20px;
        margin-bottom: 20px;
    }
    .footer-link-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 175%;
    align-items: center;
    }
    .footer-icons{
        margin-bottom: 20px;
    }
    .footer-copyright{
        margin: 20px;
    }
}
@media screen and (max-width:960px){
    .footer-container {
        background-color: #272733;
        padding: 4rem 0 2rem 0;
        padding: 20px ; 
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        color: #fff;
    }
    .footer-A{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        /* margin-left: 10%; */
        margin-right: 10%;
    }
    .footer-description{
        width: 170%;
        color: #fff;
        text-align: center;
        align-items: center;

    }
    .footer-description p{
        width: 100%;
        padding: 20px;
        padding-left: 0%;  
        padding-right: 0%;  
    }
    .links-foot{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .footer-link-items{
        padding: 20px;
        margin-bottom: 20px;
    }
    .footer-link-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 175%;
    align-items: center;
    }
    .footer-icons{
        margin-bottom: 20px;
    }
    .footer-copyright{
        margin: 20px;
    }
    
}
@media screen and (max-width:660px){
    .footer-container {
        background-color: #272733;
        padding: 4rem 0 2rem 0;
        padding: 20px ; 
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        color: #fff;
    }
    .footer-A{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        /* margin-left: 10%; */
        margin-right: 10%;
    }
    .footer-description{
        width: 170%;
        color: #fff;
        text-align: center;
    }
    .footer-description p{
        width: 100%;
        padding: 20px;
        padding-left: 0%;  
        padding-right: 0%;  
    }
    .links-foot{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .footer-link-items{
        padding: 20px;
        margin-bottom: 20px;
    }
    .footer-link-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 175%;
    align-items: center;
    }
    .footer-icons{
        margin-bottom: 20px;
    }
    .footer-copyright{
        margin: 20px;
    }
    .logo-foot{
        width:220px;
        height:40px;
    }
}