.Image__SC__video{
    width: 95%;
    align-items: center;
    z-index: 2;
    justify-content: center;
    display: flex;
    margin: 10%;
    flex-direction: column;
    margin-top: 15%;
}
.seconde-containerVideo .image_back_video{
    object-fit: cover;
    width: 95%;
    height: 500px;
    position: absolute;
    z-index: -1;
    border-radius: 6px;
    transition: transform 0.3s ease;
    
}
.seconde-containerVideo {
    cursor: pointer;
    height: 70%;
    width: 100%;
    padding-bottom: 10%;
    display: flex;
    justify-content: center;
    object-fit: contain;
    border-radius: 6px;
    background-color: rgb(242, 253, 253);
    padding-top: 5%;

}
.Image__SC__video:hover .image_back_video{
    transform: scale(1.04);
}

.seconde-containerVideo h1{
    color: #fff;
    font-size: 70px;
    margin-top: -15%;
    margin-bottom: 5%;
}
.video_play_icon{
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 55px;
    height: 55px;
    /* margin-top: 5%; */
    border-radius: 50%;
    transition: 0.3s ease-in-out;
    -webkit-animation: ripple 1s linear infinite;
    animation: ripple 1s linear infinite;
}
.video_play_icon span {
    color: #3cc2bb;
}
.video_play_icon:hover {
    transform: scale(1.1);
    transition: 0.3s ease-in-out;
}

.zoom-anim-dialog  {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
  }
  
 .zoom-anim-dialog  video {
    position: relative;
    max-width:900px;
    outline: none;
    border-radius: 7px;
  }
  .zoom-anim-dialog.active{
    visibility: visible;
    opacity: 1;
  }
@media screen and (max-width:1366px){
    .seconde-containerVideo h1{
        font-size: 45px;
    }
    .seconde-containerVideo .image_back_video{
        object-fit: cover;
        width: 90%;
        height: 60%;
        position: absolute;
        z-index: -1;
    }
    .Image__SC__video{
        width: 90%;
        align-items: center;
        z-index: 2;      
    }
    .seconde-containerVideo {
        height: 100vh;
        width: 100%;
        margin-top: -15%;
        display: flex;
        justify-content: center;
        object-fit: contain;
        border-radius: 6px;
        align-items: center;
        transition: transform 0.3s ease;
    }
}
@media screen and (max-width:960px){
    .seconde-containerVideo h1{
        font-size: 45px;
    }
    .seconde-containerVideo .image_back_video{
        object-fit: cover;
        width: 90%;
        height: 60%;
        position: absolute;
        z-index: -1;
    }
    .Image__SC__video{
        width: 90%;
        align-items: center;
        z-index: 2;      
    }
    .seconde-containerVideo {
        height: 100vh;
        width: 100%;
        margin-top: -15%;
        display: flex;
        justify-content: center;
        object-fit: contain;
        border-radius: 6px;
        align-items: center;
        transition: transform 0.3s ease;
    }
    .zoom-anim-dialog  video {
        position: relative;
        max-width:90%;
        outline: none;
        border-radius: 7px;
      }
}
@media screen and (max-width:760px){
    .seconde-containerVideo h1{
        font-size: 40px;
    }
    .seconde-containerVideo .image_back_video{
        object-fit: cover;
        width: 90%;
        height: 60%;
        position: absolute;
        z-index: -1;
    }
    .Image__SC__video{
        width: 90%;
        align-items: center;
        z-index: 2;
    }
    .seconde-containerVideo {
        height: 100vh;
        width: 100%;
        margin-top: -15%;
        margin-bottom: -5%;
        display: flex;
        justify-content: center;
        object-fit: contain;
        border-radius: 6px;
        align-items: center;
        transition: transform 0.3s ease;
    }
}
@media only screen and (max-width:667px){
    .seconde-containerVideo h1{
        font-size: 30px;
    }
    .seconde-containerVideo .image_back_video{
        object-fit: cover;
        width: 90%;
        height: 50%;
        position: absolute;
        z-index: -1;
    }
    .Image__SC__video{
        width: 90%;
        align-items: center;
        z-index: 2;     
    }
    .seconde-containerVideo {
        height: 100vh;
        width: 100%;
        margin-top: -25%;
        margin-bottom: -5%;
        display: flex;
        justify-content: center;
        object-fit: contain;
        border-radius: 6px;
        align-items: center;
        transition: transform 0.3s ease;
    }
}
@media only screen and (max-width:667px){
    .seconde-containerVideo h1{
        font-size: 30px;
    }
    .seconde-containerVideo .image_back_video{
        object-fit: cover;
        width: 90%;
        height: 50%;
        position: absolute;
        z-index: -1;
    }
    .Image__SC__video{
        width: 90%;
        align-items: center;
        z-index: 2;     
    }
    .seconde-containerVideo {
        height: 100vh;
        width: 100%;
        margin-top: -25%;
        margin-bottom: -5%;
        display: flex;
        justify-content: center;
        object-fit: contain;
        border-radius: 6px;
        align-items: center;
        transition: transform 0.3s ease;
    }
}