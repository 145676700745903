.hero-container .image-back{
    object-fit: cover;
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: 1;
}

.container{
    display: flex;
    flex-flow: row ;
    justify-content: space-between;
    align-items: center;
}

.hero-container {
    height: 100vh;
    width: 100%;
    margin-top: -5%;
    display: flex;
    flex-direction: column;
    object-fit: contain;
    justify-content: center;
}
.hero-container h1{
    color: #fff;
    width: 90%;
    font-size: 70px;
    margin-left: 8%;
    margin-top: 2%;
    z-index: 2;
}
.hero-container p{
    color: #fff;
    width: 90%;
    font-size: 55px;
    margin-left: 8%;
    margin-top: 2%;
    z-index: 2;
}
.image-log{
    position: relative; 
    margin-left: 7%;
    top:-2%; 
    margin-top: 80px; 
    width: 170px; 
    height: 140px;
    z-index: 2;
}
.background{
    z-index: 1;
    opacity:0.7;
}
.image_pc{
    width: 70%;
    height: 80%;
    margin-left: 20%;
}
@media screen and (max-width:960px){
    .container{
        display: flex;
        flex-flow: column ;
        justify-content: space-between;
        align-items: center;
    }   
    .image_pc{
        display: none;
    } 
    .hero-container h1{
        font-size: 70px;
    }
    .hero-container {
        box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.2);
        height: 100vh;
        width: 100%;
       
    }
    .hero-container .image-back{
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
    }

}
@media screen and (max-width:760px){
    .hero-container h1{
        font-size: 60px;
        margin-left: 2%;
        margin-top: 5%;
    }
    .hero-container .image-back{
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
    }
    .hero-container p{
        font-size: 30px;
        margin-top: 15%;
        margin-left: 2%;
        text-align: center;
    }
    .hero-container {
        box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.2);
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        object-fit: contain;
        text-align: center;
    }
    .image-log{
        position: relative; 
        margin-top:-60%;
        left: -35%; 
        width: 170px; 
        height: 140px;
    }
}
@media only screen and (max-width:667px){
    .hero-container h1{
        font-size: 40px;
        margin-left: 2%;
        margin-top: 5%;
    }
    .hero-container .image-back{
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
    }
    .hero-container p{
        font-size: 30px;
        margin-top: 15%;
        margin-left: 2%;
        text-align: center;
    }
    .hero-container {
        box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.2);
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        object-fit: contain;
    }
    .image-log{
        position: relative; 
        margin-top: -20%;
        left: -35%; 
        width: 130px; 
        height: 110px;
    }
}